export default `
<div class="simple-registration-page-header sign-in-box" ng-if="$ctrl.showHeader">
  <div class="text-left col-md-4 col-lg-4 col-sm-6 col-xs-6 logo-container">
    <img class="text-left utest-simple-registration-logo" src="/assets/images/uTestLogoNavDark.svg" alt="" />
  </div>
  <div ng-show="!$ctrl.completedRegistration && !$ctrl.failedRegistration" class="text-right col-md-4 col-lg-4 col-sm-6 col-xs-6 sign-in">
    <span>Already have an account?</span>
    &nbsp;
    <a target="_blank" rel="noopener noreferrer" href="/login" target="_self">Sign in</a>
  </div>
</div>

<div class="container-fluid">
  <div class="clearfix">
    <div class="sign-up-form-container" ng-show="!$ctrl.completedRegistration && !$ctrl.failedRegistration">
      <div class="registration-intro" ng-if="$ctrl.customRegistration && $ctrl.customRegistration.info_snippet">
        <article ng-bind-html="$ctrl.customRegistration.info_snippet | uMarkdown | trustAsHtml"></article>
      </div>
      <form novalidate name="simpleRegistrationForm" class="user-form" ng-class="{'invalid': (!simpleRegistrationForm.$valid && simpleRegistrationForm.$$submitted)}">
        <div>
          <h3 class="form-header-text">Join the uTest Community</h3>
        </div>
        <div class="simple-registration-form-group-box">
          <!-- BASIC INFO -->
          <div class="row">
            <div class="form-group col-xs-12 col-md-6 col-sm-6">
              <div>
                <label class="simple-registration-label" for="firstName">First Name</label>
              </div>
              <input
                required
                type="text"
                id="firstName"
                name="firstName"
                aria-label="First name"
                ng-model="$ctrl.registrationData.firstName"
                ng-pattern="$ctrl.name_pattern"
                ng-minlength="2"
                ng-maxlength="50"
                ng-auto-focus="{onlyFocusWhenEmpty: true}"
                focus-on="firstNameFocus"
                aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.firstName.$invalid ? 'firstNameError' : ''}}"
                aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.firstName.$invalid}}"
              />
              <span
                id="firstNameError"
                class="error-msg"
                ng-show="!simpleRegistrationForm.firstName.$valid && simpleRegistrationForm.$$submitted"
                aria-live="polite"
              >
                {{simpleRegistrationForm.firstName.$error.required ? 'Please enter a valid first name' : (simpleRegistrationForm.firstName.$error.pattern ? 'No special characters': 'Between 2 and 50 characters required')}}
              </span>
            </div>
            <div class="form-group col-xs-12 col-md-6 col-sm-6">
              <div>
                <label class="simple-registration-label" for="lastName">Last Name</label>
              </div>
              <input
                required
                type="text"
                id="lastName"
                name="lastName"
                aria-label="Last Name"
                ng-model="$ctrl.registrationData.lastName"
                ng-pattern="$ctrl.name_pattern"
                ng-minlength="2"
                ng-maxlength="50"
                aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.lastName.$invalid ? 'lastNameError' : ''}}"
                aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.lastName.$invalid}}"
              />
              <span
                id="lastNameError"
                class="error-msg"
                ng-show="!simpleRegistrationForm.lastName.$valid && simpleRegistrationForm.$$submitted"
                aria-live="polite"
              />
                {{simpleRegistrationForm.lastName.$error.required ? 'Please enter a valid last name' : (simpleRegistrationForm.lastName.$error.pattern ? 'No special characters': 'Between 2 and 50 characters required')}}
              </span>
            </div>
            <div class="form-group col-xs-12">
              <div>
                <label class="simple-registration-label" for="email">Email Address</label>
              </div>
              <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    ng-change="$ctrl.updatePayPalEmail()"
                    ng-model="$ctrl.registrationData.email"
                    ng-model-options="{ allowInvalid: true, debounce: 250 }"
                    ng-pattern="$ctrl.email_pattern"
                    autocomplete="email"
                    aria-label="Email Address"
                    aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.email.$invalid ? 'emailError' : ''}}"
                    aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.email.$invalid}}">
              <span id="emailError" class="error-msg" ng-show="!simpleRegistrationForm.email.$valid && simpleRegistrationForm.$$submitted" aria-live="polite">
                Please enter a valid email
              </span>
            </div>

            <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 select2-theme-form">
              <div>
                <label id="birthLabel" for="birthDate">Date of Birth</label>
              </div>
              <div>
                <div id="birthDate" name="birthDate" class="ui-select-box">
                  <div class="col-xs-5 col-md-5 col-lg-5 months">
                    <select
                      class="signup-form-input-select"
                      ng-model="$ctrl.registrationData.birthDate.month"
                      aria-label="Birth month"
                      ng-required="true"
                      name="birthMonth"
                      id="birthMonth"
                      ng-change="$ctrl.selectMonth()"
                      aria-describedby="birthDateError"
                      aria-invalid="{{(simpleRegistrationForm.$submitted || (simpleRegistrationForm.birthMonth.$touched && simpleRegistrationForm.birthMonth.$dirty)) && !!simpleRegistrationForm.birthMonth.$invalid}}"
                    >
                      <option disabled selected value>Month</option>
                      <option
                        ng-repeat="month in $ctrl.months"
                        aria-selected="{{registrationData.birthDate.month === month.id.toString()}}"
                        value="{{month.id}}"
                      >
                        {{month.name}}
                      </option>
                    </select>
                  </div>
                  <div class="col-xs-3 col-md-3 col-lg-3 days">
                    <select
                      class="signup-form-input-select"
                      ng-model="$ctrl.registrationData.birthDate.day"
                      aria-label="Birth day"
                      ng-required="true"
                      name="birthDay"
                      id="birthDay"
                      ng-change="$ctrl.selectDay()"
                      aria-describedby="birthDateError"
                      aria-invalid="{{(simpleRegistrationForm.$submitted || (simpleRegistrationForm.birthDay.$touched && simpleRegistrationForm.birthDay.$dirty)) && !!simpleRegistrationForm.birthDay.$invalid}}"
                    >
                      <option disabled selected value>Day</option>
                      <option
                        ng-repeat="day in $ctrl.days"
                        aria-selected="{{registrationData.birthDate.day === day.toString()}}"
                        value="{{day}}"
                      >
                        {{day}}
                      </option>
                    </select>
                  </div>
                  <div class="col-xs-4 col-md-4 col-lg-4 years">
                    <select
                      class="signup-form-input-select"
                      ng-model="$ctrl.registrationData.birthDate.year"
                      aria-label="Birth year"
                      ng-required="true"
                      name="birthYear"
                      id="birthYear"
                      ng-change="$ctrl.selectYear()"
                      aria-describedby="birthDateError"
                      aria-invalid="{{(simpleRegistrationForm.$submitted || (simpleRegistrationForm.birthYear.$touched && simpleRegistrationForm.birthYear.$dirty)) && !!simpleRegistrationForm.birthYear.$invalid}}"
                    >
                      <option disabled selected value>Year</option>
                      <option
                        ng-repeat="year in $ctrl.years"
                        aria-selected="{{registrationData.birthDate.year === year.toString()}}"
                        value="{{year}}"
                      >
                        {{year}}
                      </option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <span id="birthDateError" class="error-msg" ng-show="simpleRegistrationForm.birthDay.$invalid || simpleRegistrationForm.birthMonth.$invalid || simpleRegistrationForm.birthYear.$invalid">
                      Date of birth is required
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group col-xs-12 select2-theme-form">
              <div>
                <label class="simple-registration-label" for="country">Country</label>
              </div>
              <div class="ui-select">
                <select
                  class="signup-form-input-select"
                  ng-model="$ctrl.registrationData.countryId"
                  aria-label="Country"
                  ng-required="true"
                  name="countryId"
                  id="countryId"
                  ng-change="$ctrl.selectCountry()"
                  aria-describedby="countryIdError"
                  aria-invalid="{{(simpleRegistrationForm.$submitted || (simpleRegistrationForm.countryId.$touched && simpleRegistrationForm.countryId.$dirty)) && !!simpleRegistrationForm.countryId.$invalid}}"
                >
                  <option disabled selected value>Select a country</option>
                  <option
                    ng-repeat="country in $ctrl.countries"
                    aria-selected="{{registrationData.birthDate.countryId === country.id.toString()}}"
                    value="{{country.id}}"
                  >
                    {{country.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group col-xs-12">
            <div>
              <input
                type="radio"
                id="paymentPaypal"
                value="paypal"
                name="paymentType"
                ng-model="$ctrl.registrationData.paymentType"
                ng-change="$ctrl.updatePaymentType()"
              />
              <label for="paymentPaypal">Get paid via PayPal using the following email address</label>
              <div ng-show="$ctrl.registrationData.paymentType === 'paypal'">
                <input
                  ng-required="$ctrl.registrationData.paymentType !== 'later' && !$ctrl.paypalEmailSame"
                  id="paypalEmail"
                  name="paypalEmail"
                  type="email"
                  ng-disabled="$ctrl.paypalEmailSame"
                  ng-model="$ctrl.registrationData.paypalEmail"
                  ng-model-options="{ debounce: 500 }"
                  ng-pattern="$ctrl.email_pattern"
                  autocomplete="email"
                  aria-label="PayPal Email Address"
                  aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.paypalEmail.$invalid ? 'paypalEmailError' : ''}}"
                  aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.paypalEmail.$invalid}}"
                />
                <span
                  id="paypalEmailError"
                  class="error-msg"
                  ng-show="!simpleRegistrationForm.paypalEmail.$valid && simpleRegistrationForm.$$submitted && !$ctrl.paypalEmailSame" aria-live="polite"
                >
                  Please enter a valid PayPal email
                </span>
                <div>
                  <label class="input-check-box same-as-above">
                    <input type="checkbox" class="same-as-above" name="sameAsAbove" id="sameAsAboveSetting" ng-model="$ctrl.paypalEmailSame" ng-click="$ctrl.togglePaypalEmailSame()"
                          aria-label="Same as above">Same as above
                          <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          <div>
          <input
            type="radio"
            id="paymentSetupLater"
            value="later"
            name="paymentType"
            ng-model="$ctrl.registrationData.paymentType"
            ng-change="$ctrl.updatePaymentType()"
          />
          <label for="paymentSetupLater">Complete Sign Up now and set payment method later</label>
        </div>



        <!-- PRIVACY POLICY -->
        <div class="row">
          <div class="col-xs-12">
            <div class="privacy-policy-container">
              <label class="simple-registration-label" for="privacySetting">Privacy & Security Policy</label>
              <label class="input-check-box signup-consent">
                <input type="checkbox" name="privacySetting" id="privacySetting" ng-model="$ctrl.registrationData.privacySetting" required
                        aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.privacySetting.$invalid ? 'privacyError' : ''}}"
                        aria-label="I have read and accept the Privacy & Security Policy"
                        aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.privacySetting.$invalid}}">
                        I have read and accept the <a class="privacy-policy-link" href="/privacy-policy" target="_blank">Privacy & Security Policy</a>
                <span class="checkmark" ng-class="{error: simpleRegistrationForm.privacySetting.$error.required}"></span>
                <span id="privacyError" class="error-msg" ng-show="!simpleRegistrationForm.privacySetting.$valid && simpleRegistrationForm.$$submitted" aria-live="polite">
                  Please agree to the Privacy &amp; Security Policy
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="row simple-registration-recaptcha" ng-if="$ctrl.recaptchaKey">
          <div class="col-xs-12">
              <div vc-recaptcha id="recaptcha"
                key="$ctrl.recaptchaKey"
                required="true"
                on-create="$ctrl.setWidgetId(widgetId)"
                on-success="$ctrl.onCaptchaResponse(response)"
                on-expire="$ctrl.cbExpiration()">
                </div>
            </div>
          </div>
          <div class="simple-registration-recaptcha-error">
            <input type="hidden" ng-model="$ctrl.registrationData.recaptcha_response" required name="recaptcha">
              <span class="error-msg" ng-show="!simpleRegistrationForm.recaptcha_response.$valid && !$ctrl.showExpiredRecaptchaError" aria-live="polite">
                You must complete reCaptcha to continue.
            </span>
            <span class="error-msg" ng-show="$ctrl.showExpiredRecaptchaError" aria-live="polite">
                Unable to validate reCaptcha. Please try again later.
            </span>
          </div>

          <!-- Submit button -->
          <div class="pull-left submit-button simple-registration-btn-container">
            <button class="btn btn-blue simple-registration-btn" id="laddaBtn" ng-click="$ctrl.submitForm(simpleRegistrationForm);"
              aria-describedby="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.$invalid ? 'formErrorMessage' : ''}}"
              aria-label="Sign Up"
              aria-invalid="{{simpleRegistrationForm.$$submitted && !!simpleRegistrationForm.$invalid}}">
              <span class="simple-registration-btn-text">Sign Up</span>
            </button>
            <div style="display: block;" ng-show="!simpleRegistrationForm.$valid && simpleRegistrationForm.$$submitted" id="formErrorMessage" class="error-msg" aria-live="polite">
               Please correct the errors above before continuing
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <simple-registration-success
    ng-show="$ctrl.completedRegistration"
    custom-registration="$ctrl.customRegistration"
    user="$ctrl.user"
  ></simple-registration-success>
  <simple-registration-error ng-show="$ctrl.failedRegistration"></simple-registration-error>
</div>
`;
