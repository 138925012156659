import { StateService } from '@uirouter/core';

class NavSidebarUserInfoCtrl {
  constructor($state: StateService, ConfigService, UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.isExpanded = false;
      ctrl.state = $state;
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
      });
      UserService.ssoEnabled().then((res) => {
        if (res !== null) {
          ctrl.ssoEnabled = res.data;
        } else {
          ctrl.ssoEnabled = false;
        }
      });
      ConfigService.config().then((res) => {
        const testerAppBaseUrl = res.data.profile_url;
        ctrl.testerProfileUrl = `${testerAppBaseUrl}/account/my-profile/`;
        ctrl.paymentsUrl = `${testerAppBaseUrl}/account/payments`;
        ctrl.accountSecurityUrl = `${testerAppBaseUrl}/account/security`;
        ctrl.advancedSettingsUrl = `${testerAppBaseUrl}/account/advanced`;
      });

      ctrl.userProfileSelected = slug => ctrl.state.params.profileId === slug;
    };

    ctrl.toggleDropdown = function () {
      ctrl.isExpanded = !ctrl.isExpanded;
    };

    ctrl.expand = function () {
      ctrl.isExpanded = true;
    };

    ctrl.collapse = function () {
      ctrl.isExpanded = false;
    };
  }
}

NavSidebarUserInfoCtrl.$inject = ['$state', 'ConfigService', 'UserService'];

export default NavSidebarUserInfoCtrl;
