<div *ngIf="staticPage" [innerHTML]="staticPage.content | blindlyTrustHtml"></div>

<div class="why-utest" *ngIf="!staticPage">
  <header class="why-utest__header" [ngStyle]="{'background-image': 'url(' + bgUrl + ')'}">
    <h1 class="why-utest__header-heading" [innerHTML]="textContent.headerHeading"></h1>
    <span class="why-utest__header-desc" [innerHTML]="textContent.headerDesc"></span>
    <div class="why-utest__header-video">
      <ut-wistia-popover-player [hashed_id]="videoId"></ut-wistia-popover-player>
    </div>
  </header>
  <div class="why-utest__sections">
    <section class="why-utest__community">
      <ut-commmunity-nav></ut-commmunity-nav>
      <div class="why-utest__community-content">
        <h2 class="why-utest__community-content-heading" [innerHTML]="textContent.communityHeading"></h2>
        <span class="why-utest__community-content-desc" [innerHTML]="textContent.communityDesc"></span>
      </div>
    </section>
    <section class="why-utest__projects">
      <div class="why-utest__projects-media">
        <img class="project-board__image" [src]="images.projectBoard" alt="uTest Project board"/>
        <img class="approved-bugs__image" [src]="images.approvedBugs" alt="uTest Approved Bugs"/>
      </div>
      <div class="why-utest__projects-content">
        <h2 class="why-utest__projects-content-heading" [innerHTML]="textContent.projectsHeading"></h2>
        <div class="why-utest__projects-content-desc">
          <span class="why-utest__projects-content-desc-header" [innerHTML]="textContent.projectsDesc1"></span>
          <div class="why-utest__projects-content-desc-icon-list">
            <ul>
              <li *ngFor="let desc of textContent.projectsList; let i = index">
                <img class="streamline__image" [src]="images.streamlineIcons[i]" alt=""/>
                {{ desc }}
              </li>
            </ul>
          </div>
          <span class="why-utest__projects-content-desc-footer" [innerHTML]="textContent.projectsDesc2"></span>
        </div>
      </div>
    </section>
    <section class="why-utest__academy">
      <div class="why-utest__academy-media">
        <img class="academy__image" [src]="images.academy" alt="uTest Academy"/>
        <img class="academy-article-lesson__image"
             [src]="images.academyArticleLesson" alt="uTest Academy lessons"/>
      </div>
      <div class="why-utest__academy-content">
        <h2 class="why-utest__academy-content-heading" [innerHTML]="textContent.academyHeading"></h2>
        <span class="why-utest__academy-content-desc" [innerHTML]="textContent.academyDesc"></span>
      </div>
    </section>
    <section class="why-utest__articles-forums">
      <div class="why-utest__articles-forums-media">
        <img class="articles__image" [src]="images.articles" alt="uTest Articles"/>
        <img class="article-card__image" [src]="images.articleCard" alt="uTest single article"/>
      </div>
      <div class="why-utest__articles-forums-content">
        <h2 class="why-utest__articles-forums-content-heading" [innerHTML]="textContent.articlesForumsHeading"></h2>
        <span class="why-utest__articles-forums-content-desc" [innerHTML]="textContent.articlesForumsDesc"></span>
      </div>
    </section>

    <section class="why-utest__trusted-secure">
      <div class="why-utest__trusted-secure-media">
        <img class="trusted-secure__image" [src]="images.trustedSecure" alt="uTest Trusted & Secure"/>
      </div>
      <div class="why-utest__trusted-secure-content">
        <h2 class="why-utest__trusted-secure-content-heading" [innerHTML]="textContent.trustedSecureHeading"></h2>
        <span class="why-utest__trusted-secure-content-desc" [innerHTML]="textContent.trustedSecureDesc"></span>
      </div>
    </section>
  </div>
  <ut-quotes-nav></ut-quotes-nav>
</div>
