export default `
<div class="nav-sidebar-user-card">
  <div ntux-avatar-badges="'me'" class="nav-sidebar-user-card__image"></div>
  <div class="nav-sidebar-user-card-info">
    <div class="nav-sidebar-user-card-info__name">
      {{$ctrl.user.name}}
    </div>
    <div class="nav-sidebar-user-card-info__details">
      Tester ID: {{$ctrl.user.platform_id}}
    </div>
    <div class="nav-sidebar-user-card-rating nav-sidebar-user-card-rating--{{$ctrl.user.functional_rating}}">
      <div class="nav-sidebar-user-card-rating__text nav-sidebar-user-card-rating__text--{{$ctrl.user.functional_rating}}">
        {{$ctrl.user.functional_rating}}
      </div>
    </div>
  </div>
  <nav-sidebar-dropdown-toggle
    class="nav-sidebar-user-card-dropdown__arrow"
    on-click="$ctrl.toggleDropdown()"
    expanded="$ctrl.isExpanded"
    name="User"
  >
    <i class="material-icons" aria-hidden="true">{{$ctrl.isExpanded ? 'expand_less' : 'expand_more'}}</i>
  </nav-sidebar-dropdown-toggle>
</div>
<ul role="menu" ng-show="$ctrl.isExpanded" ng-class="$ctrl.isExpanded && 'nav-sidebar-dropdown--expanded'">
  <nav-sidebar-dropdown-item text="Tester Profile" url="{{$ctrl.testerProfileUrl}}"></nav-sidebar-dropdown-item>
  <nav-sidebar-dropdown-item text="Community Profile" route="profile.about({profileId: $ctrl.currentUser.slug})" highlight="$ctrl.userProfileSelected($ctrl.currentUser.slug)" expand-parent="$ctrl.expand()" collapse-parent="$ctrl.collapse()"></nav-sidebar-dropdown-item>
  <nav-sidebar-dropdown-item text="Payments" url="{{$ctrl.paymentsUrl}}"></nav-sidebar-dropdown-item>
  <nav-sidebar-dropdown-item text="Account Security" ng-if="!$ctrl.ssoEnabled" url="{{$ctrl.accountSecurityUrl}}"></nav-sidebar-dropdown-item>
  <nav-sidebar-dropdown-item text="Advanced Settings" url="{{$ctrl.advancedSettingsUrl}}"></nav-sidebar-dropdown-item>
  <nav-sidebar-dropdown-item text="Sign Out" url="/logout"></nav-sidebar-dropdown-item>
<ul>
`;
