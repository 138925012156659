import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'blindlyTrustHtml',
})
export class BlindlyTrustHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(html): SafeHtml {
    // marks the HTML as trusted
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
