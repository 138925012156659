export default `
<div id="mainContent">
  <div class="section-home-cover">
    <div class="section-home-cover-bg">
      <div ng-if="!currentUser" ng-style="{'background-image': 'url(' + unauthHeaderBg + ')'}" class="unauthenticated-project-bg"> </div>
      <div ng-if="currentUser" bg-img="{{coverBg}}" swap="{{headerData.coverBg.large}}"></div>
    </div>
    <div class="block container" ng-if="currentUser">
      <h1 class="section-title">{{headerData.title}}</h1>
      <p class="section-desc" ng-bind-html="headerData.description">
      </p>
    </div>
    <div class="block container" ng-if="!currentUser">
      <div class="row">
        <div class="col-sm-3 hidden-xs"></div>
        <div class="col-sm-6 col-xs-12 horizontal-center">
          <h1 class="section-big-title-unauth">{{headerData.title}}</h1>
          <p <p class="section-desc-unauth" ng-bind-html="headerData.unauthDescription">
          </p>
        </div>
        <div class="col-sm-3 hidden-xs"></div>
      </div>
    </div>
  </div>
  <div class="block container" style="min-height: 262px">
    <div class="clearfix project-filter-container">

      <div class="filter project-filter">
        <ui-select id="osDropdown" role="combobox" aria-expanded="{{$select.open}}" class="select2-theme-form" ng-model="filterProject.os" on-select="$ctrl.updateFilter('os')" aria-label="{{$select.selected}}">
          <ui-select-match allow-clear aria-label="{{$select.selected}}" placeholder="Select an OS">
            {{$select.selected}}
          </ui-select-match>
          <ui-select-choices minimum-input-length="-1" repeat="os in availableOSs | filter: $select.search">
            <div ng-bind-html="os | highlight: $select.search"></div>
          </ui-select-choices>
        </ui-select>
      </div>

      <div class="filter project-filter">
        <ui-select id="countryDropdown" role="combobox" aria-expanded="{{$select.open}}" class="select2-theme-form" ng-model="filterProject.country" on-select="$ctrl.updateFilter('country')" aria-label="{{ $select.selected }}">
          <ui-select-match allow-clear aria-label="{{$select.selected}}" placeholder="Select a country">
            {{$select.selected}}
          </ui-select-match>
          <ui-select-choices minimum-input-length="-1" repeat="country in availableCountries | filter: $select.search">
            <div ng-bind-html="country | highlight: $select.search"></div>
          </ui-select-choices>
        </ui-select>
      </div>
    </div>

    <uib-tabset>
      <uib-tab ng-repeat="tab in $ctrl.tabs" heading="{{tab}}" ng-show="shouldShow(tab)" select="selectTab(tab)" active="$ctrl.tabStates[tab]">
        <project-feed-item feed="groups[tab]" project="project" ng-repeat="project in groups[tab].feedItems" class="section-home-feed"></project-feed-item>
        <p class="feed-loading-message" ng-if="!groups[tab].isLoading && groups[tab].feedItems.length == 0" >No Results</p>
        <load-more load-callback="loadMoreItems(groups[tab])" is-loading="groups[tab].isLoading" is-last-page="groups[tab].isLastPage" fade-target="footer"></load-more>
        <back-to-top feed-items="groups[tab].feedItems" is-loading="groups[tab].isLoading" is-last-page="groups[tab].isLastPage"></back-to-top>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
