class UnauthenticatedContainerCtrl {
  constructor($transitions, $rootScope, $scope) {
    // @ts-ignore
    this.$onInit = () => {
      const ctrl = this as any;
      ctrl.showHeaderFooter = true;

      $scope.$watch(() => $rootScope.showUnauthenticatedHeaderFooter, (newValue) => {
        if (newValue !== undefined) {
          ctrl.showHeaderFooter = newValue;
        }
      });

      $transitions.onStart({}, (transition) => {
        if (!['about-us-new', 'why-utest-new', 'resources', 'home'].includes(transition.to().name)) {
          $rootScope.showUnauthenticatedHeaderFooter = true;
        }
      });
    };
  }
}

UnauthenticatedContainerCtrl.$inject = ['$transitions', '$rootScope', '$scope'];

export default UnauthenticatedContainerCtrl;
