import _ from 'lodash';
import { StateService } from '@uirouter/core';
import { setCurrentUser } from '../../actions';

class UserService {
  $cookies: any;
  $http: ng.IHttpService;
  $q: ng.IQService;
  $state: StateService;
  cachedMePromise: any;
  lastMeFetch: any;
  store: any;
  platformAccessToken: any;
  ConfigService: any;
  $rootScope: any;
  constructor($state: StateService, $http: ng.IHttpService, store, $cookies, $q: ng.IQService, $rootScope) {
    this.$state = $state;
    this.$http = $http;
    this.cachedMePromise = null;
    this.lastMeFetch = null;
    this.store = store;
    this.$cookies = $cookies;
    this.$q = $q;
    this.platformAccessToken = null;
    this.$rootScope = $rootScope;
  }

  addProfileUrl(user) {
    user.url = this.$state.href('profile.about', { profileId: user.id }, { absolute: true });
    return user;
  }

  generatedData(response) {
    response.data = this.addProfileUrl(response.data);
    return response;
  }

  addResponseUrls(response) {
    response.data = _.map(response.data, _.bind(this.addProfileUrl, this));
    return response;
  }

  me(allowedAge) {
    const allowedAgeMs = allowedAge * 1000;
    const now = Date.now();
    const { dispatch } = this.store;
    if (this.$cookies.get(this.$rootScope.csrfCookieName) !== undefined
       && (!this.cachedMePromise
       || _.isNull(this.lastMeFetch)
       || ((now - this.lastMeFetch) > allowedAgeMs))) {
      const boundAddUrl = _.bind(this.generatedData, this);

      this.lastMeFetch = now;
      this.cachedMePromise = this.$http.get('/api/v1/users/me').then(boundAddUrl);
      this.$http.get('/api/v1/users/me/platform_access_token').then(({ data }) => {
        this.platformAccessToken = data['platform_access_token']; // eslint-disable-line
      });

      this.cachedMePromise.then(({ data }) => dispatch(setCurrentUser({ ...data, platformAccessToken: this.platformAccessToken })));
    }

    return this.$q.when(this.cachedMePromise);
  }

  chatUuid() {
    return this.$http.get('/api/v1/users/me/chat_uuid');
  }

  followers(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/users/${userId}/followers`, { params: parameters });
  }

  following(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/users/${userId}/following`, { params: parameters });
  }

  updateMe(profileData) {
    // Send this request as multipart/form-data, since it might include file uploads
    // (avatar, profile banner)
    const formData = new FormData();

    _.forEach(profileData, (value, key) => {
      if (_.isPlainObject(value)) {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });

    this.cachedMePromise = null;

    return this.$http.put('/api/v1/users/me', formData, { headers: { 'Content-Type': undefined } });
  }

  setViewedCommunityPage() {
    return this.$http.patch('/api/v1/users/me', { viewed_community_page: true });
  }

  follow(id) {
    return this.$http.post(`/api/v1/users/${id}/follow`, '');
  }

  unfollow(id) {
    return this.$http.delete(`/api/v1/users/${id}/follow`);
  }

  find(id) {
    const boundAddUrl = _.bind(this.generatedData, this);
    return this.$http.get(`/api/v1/users/${id}`).then(boundAddUrl);
  }

  createUser(registrationData) {
    return this.$http.post('/api/v1/users', registrationData);
  }

  createLightTester(registrationData) {
    return this.$http.post('/api/v1/users/create_light_tester', registrationData);
  }

  confirmEmail(emailCode) {
    return this.$http.get('/api/v1/users/confirm_email', { params: { code: emailCode } });
  }

  updatePassword(newPassword, passwordCode) {
    return this.$http.put('/api/v1/users/update_password', { newPassword, passwordCode });
  }

  checkUserCodeValidity(userCode) {
    return this.$http.get('/api/v1/users/check_code', { params: { code: userCode } });
  }

  resendSetPasswordEmail(userCode) {
    return this.$http.put('/api/v1/users/resend_set_password', { code: userCode });
  }

  signOut() {
    return this.$http.delete('/api/v1/session', { headers: { 'Content-Type': 'application/json' } });
  }

  resendConfirmationEmail(email) {
    return this.$http.post('/api/v1/users/resend_confirmation_email', { email });
  }

  comments(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/users/${userId}/comments`, { params: parameters });
  }

  mentions(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/users/${userId}/mentions`, { params: parameters });
  }

  topics(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return this.$http.get(`/api/v1/users/${userId}/topics`, { params: parameters });
  }

  likes(userId, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return this.$http.get(`/api/v1/users/${userId}/likes`, { params: parameters });
  }

  projects() {
    return this.$http.get('/api/v1/users/me/projects');
  }

  invitations() {
    return this.$http.get('/api/v1/users/me/invitations');
  }

  getProfileBanner(user) {
    if (!user.profile_banner) {
      return {
        url: 'assets/images/profile-cover-bg.jpg',
      };
    }
    return user.profile_banner;
  }

  userRoleTitle(user) {
    if (user.is_employee) {
      return 'uTest Employee';
    }

    if (user.platform_roles.pm) {
      return 'Project Manager';
    }

    if (user.platform_roles.ttl) {
      return 'Test Team Lead';
    }

    return 'Tester';
  }

  unreadMessages() {
    return this.$http.get('/api/v1/users/me/unread_messages').catch(() => {});
  }

  ssoEnabled() {
    return this.$http.get('/api/v1/users/me/sso_enabled');
  }
}

UserService.$inject = ['$state', '$http', 'store', '$cookies', '$q', '$rootScope'];

export default UserService;
