import _ from 'lodash';

import angular from 'angular';

import emojione from 'emojione';
import Dropzone from 'dropzone';
import { createStore, combineReducers } from 'redux';

import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngTouch from 'angular-touch';
import angulartics from 'angulartics';
import ngSanitize from 'angular-sanitize';
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import featureFlags from 'angular-feature-flags';
import ngMessages from 'angular-messages';
import localStorageService from 'angular-local-storage';

import CategoryTypeFilter from './components/filter/category-type';
import CategoryColorFilter from './components/filter/category-color.filter';
import DanteCleanUpFilter from './components/filter/dante-clean-up.filter';
import AppendCountFilter from './components/filter/append_count.filter';
import PluralizeFilter from './components/filter/pluralize.filter';
import CapitalizeFilter from './components/filter/capitalize.filter';
import UMarkdownFilter from './components/filter/u-markdown.filter';
import EmojiUnicodeFilter from './components/filter/emoji-unicode.filter';
import TrustAsHtmlFilter from './components/filter/trust-as-html.filter';
import TrustAsUrlFilter from './components/filter/trust-as-url.filter';

import UnauthenticatedContainer from './components/unauthenticated/unauthenticated-container.component';
import UnauthenticatedHeader from './components/unauthenticated/unauthenticated-header.component';
import UnauthenticatedHeaderNavItem from './components/unauthenticated/unauthenticated-header-nav-item.component';
import UnauthenticatedFooter from './components/unauthenticated/unauthenticated-footer.component';
import NavSidebar from './components/nav-sidebar/nav-sidebar.component';
import NavSidebarUserInfo from './components/nav-sidebar/nav-sidebar-user-info.component';
import NavSidebarProjects from './components/nav-sidebar/nav-sidebar-projects.component';
import NavSidebarItem from './components/nav-sidebar/nav-sidebar-item.component';
import NavSidebarDropdown from './components/nav-sidebar/nav-sidebar-dropdown.component';
import NavSidebarDropdownItem from './components/nav-sidebar/nav-sidebar-dropdown-item.component';
import NavSidebarDropdownToggle from './components/nav-sidebar/nav-sidebar-dropdown-toggle.component';
import NavSidebarFooter from './components/nav-sidebar/nav-sidebar-footer.component';
import NavSidebarToggle from './components/nav-sidebar/nav-sidebar-toggle.component';
import NavHeader from './components/nav-header/nav-header.component';
import NavHeaderSearchField from './components/nav-header/nav-header-search-field.component';
import NavHeaderNotifications from './components/nav-header/nav-header-notifications.component';
import NavHeaderChat from './components/nav-header/nav-header-chat.component';
import NavHeaderHelp from './components/nav-header/nav-header-help.component';
import AutoPadding from './components/nav-utilities/auto-padding.directive';
import Scrolly from './components/nav-utilities/scrolly.directive';
import InfiniteScroll from './components/nav-utilities/infinite-scroll.directive';
import CommunityFeatured from './components/community/featured/community-featured.component';
import CommunityFeaturedListItem from './components/community/featured/community-featured-list-item.component';
import CommunityStatusUpdate from './components/community/status-update/community-status-update.component';
import CommunityTrending from './components/community/trending/community-trending.component';
import CommunityTrendingListItem from './components/community/trending/community-trending-list-item.component';
import CommunityFeed from './components/community/feed/community-feed.component';
import CommunityFeedItem from './components/community/feed/community-feed-item.component';
import CommunityFeedItemComment from './components/community/feed/community-feed-item-comment.component';
import communityFeedItemLikeListOverlay from './components/community/feed/community-feed-item-like-list-overlay.component';
import communityUserListItem from './components/community/user/community-user-list-item.component';
import communityFollowBtn from './components/community/user/community-follow-btn.component';
import communityFeedStatusUpdate from './components/community/status-update/community-feed-status-update.component';
import communityFeedItemDeleteOverlay from './components/community/status-update/community-feed-item-delete-overlay.component';
import communityFeedItemFlag from './components/community/flag/community-feed-item-flag.component';
import communityFeedItemHide from './components/community/hide/community-feed-item-hide.component';
import communityFeedItemSubscribe from './components/community/subscribe/community-feed-item-subscribe.component';
import viewMore from './components/community/user/view-more.component';
import DeleteBtn from './components/delete-btn/delete-btn.component';
import NtuxSelect from './components/ntux-select/ntux-select.component';

import academyHome from './components/academy/academy-home.component';
import academyCourse from './components/academy/course/academy-course.component';
import academyCourseTrackQuiz from './components/academy/quiz/academy-course-track-quiz.component';
import academyCourseTrackReportCard from './components/academy/report-card/academy-course-track-report-card.component';
import academyCourseSection from './components/academy/course/course-section/academy-course-section.component';
import courseTrackCard from './components/academy/course-track-card/course-track-card.component';
import courseTrackQuiz from './components/academy/quiz/course-track-quiz.component';
import courseTrackQuizProgressBar from './components/academy/quiz/course-track-quiz-progress-bar.component';
import courseTrackQuizQuestion from './components/academy/quiz/course-track-quiz-question.component';
import courseTrackQuizQuestionChoice from './components/academy/quiz/course-track-quiz-question-choice.component';
import courseTrackReportCard from './components/academy/report-card/course-track-report-card.component';
import courseTrackNav from './components/academy/course-track-nav/course-track-nav.component';
import courseTrackNavItem from './components/academy/course-track-nav/course-track-nav-item.component';
import quizResultItem from './components/academy/quiz/quiz-result-item.component';

import articles from './components/article/article-index/articles.component';
import singleArticle from './components/article/single-article/single-article.component';
import pendingArticle from './components/article/pending-article/pending-article.component';

import projectFeedItem from './components/project/project-feed-item/project-feed-item.component';
import singleProject from './project/single/single-project.component';

import Dashboard from './components/dashboard/dashboard.component';
import DashboardCardActivity from './components/dashboard/activity/dashboard-card-activity.component';
import DashboardCardMyDevices from './components/dashboard/my-devices/dashboard-card-my-devices.component';
import DashboardCardRequestedDevices from './components/dashboard/requested-devices/dashboard-card-requested-devices.component';
import DashboardCardMyDevicesListItem from './components/dashboard/my-devices/dashboard-card-my-devices-list-item.component';
import UserDeviceService from './components/services/user-device.service';
import DashboardCardProfileCompletion from './components/dashboard/profile-completion/dashboard-card-profile-completion.component';
import DashboardActivityCommunityTab from './components/dashboard/activity/dashboard-activity-community-tab.component';
import DashboardAllTab from './components/dashboard/activity/dashboard-all-tab.component';
import DashboardActivityInvitationsTab from './components/dashboard/activity/dashboard-activity-invitations-tab.component';
import DashboardActivityProjectsTab from './components/dashboard/activity/dashboard-activity-projects-tab.component';
import DashboardActivityProjectListItem from './components/dashboard/activity/dashboard-activity-project-list-item.component';
import DashboardActivityProjectListFooter from './components/dashboard/activity/dashboard-activity-project-list-footer.component';
import DashboardActivityProjectEmptyListNotification from './components/dashboard/activity/dashboard-activity-project-empty-list-notification.component';
import ActivityListItem from './components/activity/activity-list-item.component';
import DashboardNewJoinerChecklist from './components/dashboard/new-joiner-checklist/dashboard-new-joiner-checklist.component';
import NewJoinerChecklistItem from './components/dashboard/new-joiner-checklist/new-joiner-checklist-item.component';
import ActivityFeed from './components/activity/activity-feed.component';
import ReferralCampaignFeed from './components/referral-campaign/referral-campaign-feed.component';
import ReferralCampaignDetail from './components/referral-campaign/referral-campaign-detail.component';
import FeedbackService from './components/services/feedback.service';

import CommunityRegistrationForm from './community-registration/community-registration-form.component';

import ServiceByType from './components/services/service-by-type.service';
import CompileService from './components/services/compile.service';
import ImageDataService from './components/services/image-data.service';

import SocialSharing from './components/social-sharing/social-sharing.component';

import Flag from './components/flag/flag.directive';
import FlagService from './components/flag/flag.service';

import LoadMore from './components/load-more/load-more.directive';
import NtuxLoadMore from './components/load-more/ntux-load-more.component';
import BackToTop from './components/back-to-top/back-to-top.directive';
import LoadingDots from './components/loading-dots/loading-dots.component';

import LikeBtn from './components/like-btn/like-btn.directive';
import LikeService from './components/like-btn/like.service';
import LikeListOverlay from './components/like-btn/like-list-overlay.directive';

import FollowBtn from './components/follow-btn/follow-btn.directive';

import SubscribeService from './components/subscribe-btn/subscribe.service';
import SubscribeBtn from './components/subscribe-btn/subscribe-btn.directive';

import ArchiveService from './components/archive-btn/archive.service';
import ArchiveBtn from './components/archive-btn/archive-btn.directive';
import CommunityArchiveBtn from './components/archive-btn/community-archive-btn.component';

import CommentService from './components/comment-btn/comment.service';

import BgImg from './components/bgimg/bgimg.directive';

import RandomColor from './components/random-color/random-color.directive';

import NgEnter from './components/ng-enter/ng-enter.directive';

import RelativeDate from './components/filter/relative-date';
import TimeLeft from './components/filter/time-left';
import StrLimit from './components/filter/str-limit';
import GroupBy from './components/filter/group-by';
import CleanUp from './components/filter/clean-up';
import PrependHttp from './components/filter/prepend-http';
import ConfigService from './components/services/config.service';
import LightTesterErrorService from './components/services/light-tester-error.service';
import UploadService from './components/upload/upload.service';
import Mentions from './components/filter/mentions.filter';
import Ulinky from './components/filter/ulinky.filter';
import NumToK from './components/filter/num-to-k.filter';

import UrlService from './components/services/url.service';
import UrlExtensionService from './components/services/url-extension.service';
import FeedStore from './components/services/feed-store.factory';
import KeysetFeedStore from './components/services/keyset-feed-store.factory';
import stateStore from './components/services/state-store.factory';
import RedirectService from './components/services/redirect.service';

import UserHeartbeatPingService from './components/services/user-heartbeat-ping.service';

import ServerMessages from './components/services/server-messages.service';

import Overlay from './components/overlay/overlay.directive';

import SlideUp from './components/slide-up/slide-up.directive';

import UploadBtn from './components/upload-btn/upload-btn.component';

import IsValidService from './components/validation/is-valid.service';
import ValidationApi from './components/validation/validation-api.directive';
import ValidationMatch from './components/validation/validation-match.directive';
import ValidationUserPassword from './components/validation/validation-user-password.directive';

import AccessibleFormService from './components/services/accessible-form.service';
import AccessibleFocusTrapService from './components/services/accessible-focus-trap.service';
import AccessibleInertService from './components/services/accessible-inert.service';

import FlashMessage from './components/flash-message/flash-message.directive';
import FlashMessagePromise from './components/flash-message/flash-message-promise.directive';

import PaginatorService from './components/paginator/paginator.service';
import CommentSection from './components/comment-section/comment-section.component';
import CommentSectionShort from './components/comment-section/comment-section-short.component';

import LoadingIndicator from './components/loading-indicator/loading-indicator.directive';
import ProgressBarService from './components/services/progress-bar.service';

import UserListItem from './components/user/user-list-item.component';
import RouteClasses from './components/route-classes/route-classes.directive';

import CustomDropdown from './components/custom-dropdown/custom-dropdown.component';

import feedItem from './components/feed-item/feed-item.component';
import StatusAttachment from './components/status/status-attachment.component';
import WriteStatusFs from './components/status/write-status-fs.directive';
import WriteStatusFsTrigger from './components/status/write-status-fs-trigger.directive';
import WriteStatusAttach from './components/status/write-status-attach.directive';

import QuoteExtension from './components/showdown-extensions/quote-extension';
import internalLinkExtension from './components/showdown-extensions/internal-link-extension';
import emojioneExtension from './components/showdown-extensions/emojione-extension';

import AlertMessageService from './components/services/alert-message.service';
import AlertMessage from './components/alert-message/alert-message.component';
import AlertService from './components/alert-service/alert.service';
import AlertComponent from './components/alert-service/alert.component';
import CookieAlert from './components/cookie-agreement/cookie-alert.component';
import CookieAgreementService from './components/cookie-agreement/cookie-agreement.service';
import LinkExtractor from './components/services/link-extractor.service';
import NavigatePrevNext from './components/navigate-prev-next/navigate-prev-next.component';
import onFinishRender from './components/on-finish-render/on-finish-render.directive';

import FeedPaginator from './components/feed-paginator/feed-paginator.directive';

import HideBtn from './components/hide-btn/hide-btn.directive';
import ToggleLink from './components/toggle-link/toggle-link.component';
import suggestionTypeahead from './components/status/suggestion-typeahead.directive';
import suggestionTypeaheadTemplate from './components/status/suggestion-typeahead.html';
import DynamicTooltip from './components/dynamic-tooltip/dynamic-tooltip.directive';
import UserTooltip from './components/dynamic-tooltip/user-tooltip.directive';

import avatarBadges from './components/user/avatar-badges.directive';
import ntuxAvatarBadges from './components/user/ntux-avatar-badges.directive';

import slugInput from './components/slug-input/slug-input.component';

import EmbedlyService from './components/services/embedly.service';
import HallOfFameService from './hall-of-fame/hall-of-fame.service';
import FreezeFrameService from './components/services/freeze-frame.service';

import UserCard from './components/user/user-card.directive';
import hiddenMenu from './components/hidden-menu/hidden-menu.component';
import commentEmojis from './components/services/comment-emojis.factory';
import DeviceDetector from './sign-up/device-detector.factory';

import ModalCtrl from './components/modal/modal.controller';

import toggleClass from './components/utility/toggle-class.directive';
import landingMessages from './components/landing-messages/landing-messages.component';

import dropzone from './components/dropzone/dropzone.directive';
import dropzoneInput from './components/dropzone/dropzone-input.component';

import imgCrop from './components/img-crop/img-crop.component';

import clipboard from './components/clipboard/clipboard.directive';

import recommendations from './components/recommendations/recommendations.component';

import playerSugar from './components/player-sugar/player-sugar.directive';

import masterCheckbox from './components/master-checkbox/master-checkbox.directive';
import statDate from './components/stat-date/stat-date.component';

import progressStep from './components/sign-up/progress-step.component';
import mailSuggest from './components/sign-up/mail-suggest.directive';
import LanguageCodeFactory from './components/services/language-code.factory';
import passwordStrengthMeter from './components/sign-up/password-strength.directive';
import passwordStrengthInfoTemplate from './components/sign-up/password-strength-info.html';
import NgAutoFocus from './components/auto-focus/ng-auto-focus.directive';

import communityRegistrationPasswordStrengthMeter from './community-registration/community-registration-password-strength.directive';
import communityRegistrationPasswordStrengthInfoTemplate from './community-registration/community-registration-password-strength-info.html';

import FeatureToggleService from './components/feature-toggle/feature-toggle.service';

import './modules/meta/meta.module';

import { GOOGLE_API_KEY } from './constants';

import HomeRouter from './routes/home';
import PseudoStaticRouter from './routes/pseudo_static';
import LandingPagesRouter from './routes/landing_pages';
import RedirectRouter from './routes/redirects';
import DiscussionRouter from './routes/discussions';
import ArticlesRouter from './routes/articles';
import UserRouter from './routes/user';
import CoursesRouter from './routes/courses';
import ToolsRouter from './routes/tools';
import ProjectsRouter from './routes/projects';
import AdminRouter from './routes/admin';
import StatusRouter from './routes/status';
import SearchRouter from './routes/search';
import EmbedRouter from './routes/embed';
import SignUpRouter from './routes/signup';
import StaticContentRouter from './routes/static_content';
import ReferralCampaignsRouter from './routes/referral_campaigns';
import CommunityRegistrationsRouter from './routes/community_registrations';

import userReducer from './reducers/user_reducer';

import PageViewService from './components/services/page-view.service';

import 'angular-ui-bootstrap';
import 'angular-slugify';
import 'angular-recaptcha';
import 'angular-google-maps';
import 'angular-simple-logger/dist/angular-simple-logger';
import 'ng-showdown';
import '@uirouter/angular-hybrid';
import 'ui-select';
import 'angular-socialshare';
import 'angular-odometer-js';
import 'mailcheck';
import 'es6-promise/auto';
import SimpleRegistrationsRouter from './routes/simple_registration';
import SimpleRegistrationForm from './simple-registration/simple-registration-form.component';
import SimpleRegistrationSuccess
  from './simple-registration/success-page/simple-registration-success-page.component';
import SimpleRegistrationError
  from './simple-registration/error-page/simple-registration-error-page.component';
import SimpleRegistrationSetPasswordLinkExpired from './simple-registration/set-password/set-password-link-expired/simple-registration-set-password-link-expired.component';
import SimpleRegistrationSetPasswordError
  from './simple-registration/set-password/set-password-error-page/simple-registration-set-password-error-page.component';

let showdownProviderHack;
let httpProviderHack;

const routers = [
  HomeRouter,
  PseudoStaticRouter,
  EmbedRouter,
  DiscussionRouter,
  ArticlesRouter,
  UserRouter,
  CoursesRouter,
  ToolsRouter,
  ProjectsRouter,
  AdminRouter,
  StatusRouter,
  SearchRouter,
  RedirectRouter,
  LandingPagesRouter,
  SignUpRouter,
  ReferralCampaignsRouter,
  CommunityRegistrationsRouter,
  SimpleRegistrationsRouter,
  StaticContentRouter, // This one has to come last, it has a catch-all route.
];

angular.module('angulartics.mautic', ['angulartics'])
  .config(['$analyticsProvider', ($analyticsProvider) => {
    let properties: any = {};

    $analyticsProvider.registerSetUserProperties((props) => {
      properties = props;
    });

    $analyticsProvider.registerPageTrack((path) => {
      properties.page_url = path;

      if (window.mt) {
        window.mt('send', 'pageview', properties);
      }
    });
  }]);

const reducers = combineReducers({
  user: userReducer,
});

const store = createStore(reducers);

export const mainModule = angular.module('uTestV2', [ //eslint-disable-line
  'ng-showdown',
  'nemLogging',
  ngAnimate,
  ngCookies,
  ngTouch,
  ngSanitize,
  'ui.router',
  'ui.router.upgrade',
  'ui.bootstrap',
  'ui.select',
  '720kb.socialshare',
  'uiGmapgoogle-maps',
  'vcRecaptcha',
  angulartics,
  'angulartics.mautic',
  'uTestV2.meta',
  'slugifier',
  'ui.odometer',
  featureFlags,
  ngMessages,
  localStorageService,
])
  .run(['$templateCache', ($templateCache) => {
    $templateCache.put('app/components/status/suggestion-typeahead.html', suggestionTypeaheadTemplate);
    $templateCache.put('app/components/sign-up/password-strength-info.html', passwordStrengthInfoTemplate);
    $templateCache.put('app/community-registration/community-registration-password-strength-info.html', communityRegistrationPasswordStrengthInfoTemplate);
  }])

  .service('store', () => store)

  .controller('ModalCtrl', ModalCtrl)

  .directive('loadingIndicator', LoadingIndicator)
  .service('ProgressBarService', ProgressBarService)

  .filter('categoryType', CategoryTypeFilter)
  .filter('categoryColor', CategoryColorFilter)
  .filter('danteCleanUp', DanteCleanUpFilter)
  .filter('appendCount', AppendCountFilter)
  .filter('pluralize', PluralizeFilter)
  .filter('capitalize', CapitalizeFilter)
  .filter('uMarkdown', UMarkdownFilter)
  .filter('emojiUnicode', EmojiUnicodeFilter)
  .filter('trustAsHtml', TrustAsHtmlFilter)
  .filter('trustAsUrl', TrustAsUrlFilter)

  .component('unauthenticatedContainer', UnauthenticatedContainer)
  .component('unauthenticatedHeader', UnauthenticatedHeader)
  .component('unauthenticatedHeaderNavItem', UnauthenticatedHeaderNavItem)
  .component('unauthenticatedFooter', UnauthenticatedFooter)
  .component('navSidebar', NavSidebar)
  .component('navSidebarUserInfo', NavSidebarUserInfo)
  .component('navSidebarProjects', NavSidebarProjects)
  .component('navSidebarDropdown', NavSidebarDropdown)
  .component('navSidebarDropdownItem', NavSidebarDropdownItem)
  .component('navSidebarDropdownToggle', NavSidebarDropdownToggle)
  .component('navSidebarItem', NavSidebarItem)
  .component('navSidebarFooter', NavSidebarFooter)
  .component('navSidebarToggle', NavSidebarToggle)
  .component('navHeader', NavHeader)
  .component('navHeaderSearchField', NavHeaderSearchField)
  .component('navHeaderNotifications', NavHeaderNotifications)
  .component('navHeaderChat', NavHeaderChat)
  .component('navHeaderHelp', NavHeaderHelp)
  .directive('autoPadding', AutoPadding)
  .directive('scrolly', Scrolly)
  .directive('infiniteScroll', InfiniteScroll)
  .component('deleteBtn', DeleteBtn)
  .component('ntuxSelect', NtuxSelect)

  .component('communityFeatured', CommunityFeatured)
  .component('communityFeaturedListItem', CommunityFeaturedListItem)
  .component('communityStatusUpdate', CommunityStatusUpdate)
  .component('communityTrending', CommunityTrending)
  .component('communityTrendingListItem', CommunityTrendingListItem)
  .component('communityFeed', CommunityFeed)
  .component('communityFeedItem', CommunityFeedItem)
  .component('communityFeedItemComment', CommunityFeedItemComment)
  .component('communityFeedItemLikeListOverlay', communityFeedItemLikeListOverlay)
  .component('communityUserListItem', communityUserListItem)
  .component('communityFollowBtn', communityFollowBtn)
  .component('viewMore', viewMore)
  .component('communityFeedStatusUpdate', communityFeedStatusUpdate)
  .component('communityFeedItemDeleteOverlay', communityFeedItemDeleteOverlay)
  .component('communityFeedItemFlag', communityFeedItemFlag)
  .component('communityFeedItemHide', communityFeedItemHide)
  .component('communityFeedItemSubscribe', communityFeedItemSubscribe)

  .component('academyHome', academyHome)
  .component('courseTrackCard', courseTrackCard)
  .component('courseTrackQuiz', courseTrackQuiz)
  .component('courseTrackQuizProgressBar', courseTrackQuizProgressBar)
  .component('courseTrackQuizQuestion', courseTrackQuizQuestion)
  .component('courseTrackQuizQuestionChoice', courseTrackQuizQuestionChoice)
  .component('courseTrackReportCard', courseTrackReportCard)
  .component('quizResultItem', quizResultItem)
  .component('academyCourse', academyCourse)
  .component('academyCourseTrackQuiz', academyCourseTrackQuiz)
  .component('academyCourseTrackReportCard', academyCourseTrackReportCard)
  .component('academyCourseSection', academyCourseSection)
  .component('courseTrackNav', courseTrackNav)
  .component('courseTrackNavItem', courseTrackNavItem)

  .component('articles', articles)
  .component('singleArticle', singleArticle)
  .component('pendingArticle', pendingArticle)

  .component('projectFeedItem', projectFeedItem)
  .component('feedItem', feedItem)
  .component('singleProject', singleProject)

  .component('dashboard', Dashboard)
  .component('dashboardCardActivity', DashboardCardActivity)
  .component('dashboardCardMyDevices', DashboardCardMyDevices)
  .component('dashboardCardRequestedDevices', DashboardCardRequestedDevices)
  .component('dashboardCardMyDevicesListItem', DashboardCardMyDevicesListItem)
  .service('UserDeviceService', UserDeviceService)
  .component('dashboardCardProfileCompletion', DashboardCardProfileCompletion)
  .component('dashboardActivityCommunityTab', DashboardActivityCommunityTab)
  .component('dashboardAllTab', DashboardAllTab)
  .component('dashboardActivityInvitationsTab', DashboardActivityInvitationsTab)
  .component('dashboardActivityProjectsTab', DashboardActivityProjectsTab)
  .component('dashboardActivityProjectListItem', DashboardActivityProjectListItem)
  .component('dashboardActivityProjectListFooter', DashboardActivityProjectListFooter)
  .component('dashboardActivityProjectEmptyListNotification', DashboardActivityProjectEmptyListNotification)
  .component('activityListItem', ActivityListItem)
  .component('dashboardNewJoinerChecklist', DashboardNewJoinerChecklist)
  .component('newJoinerChecklistItem', NewJoinerChecklistItem)
  .component('activityFeed', ActivityFeed)
  .component('referralCampaignFeed', ReferralCampaignFeed)
  .component('referralCampaignDetail', ReferralCampaignDetail)
  .service('FeedbackService', FeedbackService)

  .component('communityRegistrationForm', CommunityRegistrationForm)
  .component('simpleRegistrationForm', SimpleRegistrationForm)
  .component('simpleRegistrationSuccess', SimpleRegistrationSuccess)
  .component('simpleRegistrationError', SimpleRegistrationError)
  .component('simpleRegistrationSetPasswordLinkExpired', SimpleRegistrationSetPasswordLinkExpired)
  .component('simpleRegistrationSetPasswordErrorPage', SimpleRegistrationSetPasswordError)

  .service('ServiceByType', ServiceByType)
  .service('CompileService', CompileService)
  .service('ImageDataService', ImageDataService)

  .directive('loadMore', LoadMore)
  .component('ntuxLoadMore', NtuxLoadMore)
  .directive('backToTop', BackToTop)
  .directive('loadingDots', LoadingDots)

  .directive('likeBtn', LikeBtn)
  .service('LikeService', LikeService)
  .directive('likeListOverlay', LikeListOverlay)

  .directive('flag', Flag)
  .service('FlagService', FlagService)

  .directive('followBtn', FollowBtn)

  .service('SubscribeService', SubscribeService)
  .directive('subscribeBtn', SubscribeBtn)

  .service('ArchiveService', ArchiveService)
  .directive('archiveBtn', ArchiveBtn)
  .component('communityArchiveBtn', CommunityArchiveBtn)

  .service('CommentService', CommentService)

  .component('socialSharing', SocialSharing)

  .service('UrlService', UrlService)
  .service('UrlExtensionService', UrlExtensionService)
  .factory('FeedStore', FeedStore)
  .factory('KeysetFeedStore', KeysetFeedStore)
  .factory('stateStore', stateStore)
  .factory('RedirectService', RedirectService)

  .service('UserHeartbeatPingService', UserHeartbeatPingService)

  .service('ServerMessages', ServerMessages)

  .directive('bgImg', BgImg)

  .directive('randomcolor', RandomColor)

  .directive('ngEnter', NgEnter)

  .directive('overlay', Overlay)
  .directive('onFinishRender', onFinishRender)

  .directive('slideUp', SlideUp)

  .component('uploadBtn', UploadBtn)

  .service('IsValidService', IsValidService)
  .directive('validationApi', ValidationApi)
  .directive('validationMatch', ValidationMatch)
  .directive('validationUserPassword', ValidationUserPassword)

  .service('AccessibleFormService', AccessibleFormService)
  .service('AccessibleFocusTrapService', AccessibleFocusTrapService)
  .service('AccessibleInertService', AccessibleInertService)

  .directive('flashMessage', FlashMessage)
  .directive('flashMessagePromise', FlashMessagePromise)

  .component('userListItem', UserListItem)
  .directive('routeClasses', RouteClasses)
  .component('customDropdown', CustomDropdown)

  .component('statusAttachment', StatusAttachment)
  .directive('writeStatusFs', WriteStatusFs)
  .directive('writeStatusFsTrigger', WriteStatusFsTrigger)
  .directive('writeStatusAttach', WriteStatusAttach)

  .filter('relativeDate', RelativeDate)
  .filter('timeLeft', TimeLeft)
  .filter('strLimit', StrLimit)
  .filter('groupBy', GroupBy)
  .filter('cleanUp', CleanUp)
  .filter('prependHttp', PrependHttp)
  .filter('mentions', Mentions)
  .filter('ulinky', Ulinky)
  .filter('numToK', NumToK)

  .service('ConfigService', ConfigService)
  .service('LightTesterErrorService', LightTesterErrorService)
  .service('UploadService', UploadService)
  .factory('PaginatorService', PaginatorService)
  .component('commentSection', CommentSection)
  .component('commentSectionShort', CommentSectionShort)

  .service('LinkExtractor', LinkExtractor)
  .component('navigatePrevNext', NavigatePrevNext)

  .service('AlertMessageService', AlertMessageService)
  .factory('AlertService', AlertService)
  .component('alertService', AlertComponent)
  .component('cookieAlert', CookieAlert)
  .service('CookieAgreementService', CookieAgreementService)
  .directive('hideBtn', HideBtn)
  .component('toggleLink', ToggleLink)

  .directive('feedPaginator', FeedPaginator)

  .directive('suggestionTypeahead', suggestionTypeahead)
  .directive('dynamicTooltip', DynamicTooltip)
  .directive('userTooltip', UserTooltip)

  .directive('avatarBadges', avatarBadges)
  .directive('ntuxAvatarBadges', ntuxAvatarBadges)

  .component('slugInput', slugInput)

  .service('EmbedlyService', EmbedlyService)
  .service('HallOfFameService', HallOfFameService)
  .service('FreezeFrameService', FreezeFrameService)

  .directive('userCard', UserCard)
  .component('hiddenMenu', hiddenMenu)
  .factory('commentEmojis', commentEmojis)
  .factory('DeviceDetector', DeviceDetector)

  .directive('toggleClass', toggleClass)
  .component('landingMessages', landingMessages)

  .directive('dropzone', dropzone)

  .component('imgCrop', imgCrop)
  .directive('clipboard', clipboard)

  .component('recommendations', recommendations)

  .directive('playerSugar', playerSugar)
  .directive('masterCheckbox', masterCheckbox)

  .component('statDate', statDate)

  .component('dropzoneInput', dropzoneInput)

  .component('alertMessage', AlertMessage)

  .component('progressStep', progressStep)
  .directive('mailSuggest', mailSuggest)
  .factory('LanguageCodeFactory', LanguageCodeFactory)
  .directive('passwordStrengthMeter', passwordStrengthMeter)
  .directive('ngAutoFocus', NgAutoFocus)

  .directive('communityRegistrationPasswordStrengthMeter', communityRegistrationPasswordStrengthMeter)

  .service('FeatureToggleService', FeatureToggleService)

  .service('PageViewService', PageViewService)

  .run([
    'UrlService',
    '$window',
    '$location',
    '$analytics',
    'UserService',
    '$transitions',
    '$state',
    '$timeout',
    'CookieAgreementService',
    'UserHeartbeatPingService',
    'ConfigService',
    '$rootScope',
    'Meta',
    'FeatureToggleService',
    async (UrlService, $window, $location, $analytics, UserService, $transitions, $state, $timeout, CookieAgreementService, UserHeartbeatPingService, ConfigService, $rootScope, Meta, FeatureToggleService) => {
      showdownProviderHack.loadExtension(() => internalLinkExtension(UrlService));

      showdownProviderHack.loadExtension(() => emojioneExtension(emojione));

      const config = await ConfigService.config();
      httpProviderHack.defaults.xsrfCookieName = config.data.csrf_cookie_name;
      $rootScope.csrfCookieName = config.data.csrf_cookie_name;
      $rootScope.cookieOptInCookieName = config.data.cookie_opt_in_cookie_name;
      $rootScope.cookieDomain = config.data.cookie_domain;
      $rootScope.oneTrustCookieName = config.data.onetrust_opt_in_cookie_name;
      $rootScope.oneTrustDataDomain = config.data.onetrust_data_domain;

      UserService.me().then((response) => {
        if (response !== null && _.has(response, 'data.email')) {
          $analytics.setUserProperties({ email: response.data.email });
        }
      });

      // From https://github.com/angulartics/angulartics/blob/master/src/angulartics#L346
      $transitions.onSuccess({}, (transition) => {
        const url = $analytics.settings.pageTracking.basePath + $location.url();

        let shouldUpdateMetaTagToDefault = true;

        const urlsToNotUpdateMetaTitle = [
          '/projects/',
          '/about-us',
          '/why-utest',
        ];

        for (let i = 0; i < urlsToNotUpdateMetaTitle.length; i++) {
          const urlToNotUpdateMetaTitle = urlsToNotUpdateMetaTitle[i];
          if (url.includes(urlToNotUpdateMetaTitle)) {
            shouldUpdateMetaTagToDefault = false;
          }
        }

        if (shouldUpdateMetaTagToDefault) {
          Meta.updateTag({ name: 'og:title', property: 'og:title', content: 'uTest - The Professional Network for Testers' });
        }

        setTimeout(() => $analytics.pageTrack(url, $location), 1);

        const isDirectNavigation = (transition.from().name === '');

        if (transition.to().redirectTo) {
          $timeout(() => {
            $state.go(transition.to().redirectTo, transition.params());
          });
        } else if (transition.to().redirectOnDirectNav && isDirectNavigation) {
          $timeout(() => {
            $state.go(transition.to().redirectOnDirectNav, transition.params());
          });
        }
      });

      $transitions.onRetain({}, (transition) => {
        // while working with having inline JS on static pages, we are encountering a bug where the JS is not being reloaded
        // in order to work around this, we are reloading the page when transitioning to a static page
        if (transition.to().name === 'staticcontent') {
          $window.location.reload();
        }
      });

      $transitions.onError({}, (transition) => {
        if (transition.error().detail && transition.error().detail.status === 404 && transition.targetState().name() !== 'staticcontent') {
          $state.go('fourohfour', null, { location: false });
        }

        if (transition.error().detail && transition.error().detail.status === 403) {
          $state.go('fourohone', null, { location: false });
        }
      });

      UserHeartbeatPingService.initialize($window);

      Dropzone.autoDiscover = false;

      // load various cookie agreement services based on accepted categories
      FeatureToggleService.getEnabledFeatures().then(() => CookieAgreementService.loadServices());
    }]);

mainModule.config([
  '$stateProvider',
  '$uiRouterProvider',
  '$httpProvider',
  'uiGmapGoogleMapApiProvider',
  '$locationProvider',
  '$uiViewScrollProvider',
  '$urlMatcherFactoryProvider',
  '$showdownProvider',
  '$compileProvider',
  '$analyticsProvider',
  ($stateProvider, $uiRouterProvider, $httpProvider, uiGmapGoogleMapApiProvider, $locationProvider, $uiViewScrollProvider, $urlMatcherFactoryProvider, $showdownProvider, $compileProvider, $analyticsProvider) => {
    $compileProvider.debugInfoEnabled(false);

    $showdownProvider.loadExtension(QuoteExtension);

    $showdownProvider.setOption('headerLevelStart', 7);
    $showdownProvider.setOption('literalMidWordUnderscores', true);
    $showdownProvider.setOption('strikethrough', true);
    $showdownProvider.setOption('tables', false);

    $httpProvider.interceptors.push('RedirectService');

    $uiRouterProvider.plugin(StickyStatesPlugin);

    // This is a hack to make the provider available in the 'run' phase. We need
    // it there because we need access to the UrlService, which isn't ready
    // during the 'config' phase.

    showdownProviderHack = $showdownProvider;

    $locationProvider.html5Mode(true).hashPrefix('!');

    $urlMatcherFactoryProvider.strictMode(false);

    $httpProvider.defaults.headers.common = { 'Content-Type': 'application/json' };
    $httpProvider.defaults.xsrfHeaderName = 'X-Csrf-Token';

    // This is a hack to make the provider available in the 'run' phase. We need
    // it there because we need access to the ConfigService, which (ironically) isn't ready
    // during the 'config' phase.
    httpProviderHack = $httpProvider;

    $uiViewScrollProvider.useAnchorScroll();

    uiGmapGoogleMapApiProvider.configure({
      key: GOOGLE_API_KEY,
      libraries: 'places',
    });

    $analyticsProvider.virtualPageviews(false);
  }]);

_.each(routers, router => router.route(mainModule));
